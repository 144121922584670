/**
 * Created by Aleksandr on 05.06.2024
 */

var TravelMapToolbarItem = function () {
    ToolbarItem.call(this, ToolbarItem.LOCATIONS);

    this.enable();
};

TravelMapToolbarItem.prototype = Object.create(ToolbarItem.prototype);
TravelMapToolbarItem.prototype.constructor = TravelMapToolbarItem;

TravelMapToolbarItem.prototype.isVisible = function () {
    return true;
};

TravelMapToolbarItem.prototype.onClick = function () {
    cleverapps.focusManager.display({
        focus: "TravelMap",
        actions: [
            function (f) {
                new TravelMapWindow();
                cleverapps.focusManager.onceNoWindowsListener = f;
            }
        ]
    });
};