/**
 * Created by andrey on 19.11.19.
 */

var HomefixScene = MainScene.extend({
    ctor: function (options) {
        options = options || {};
        this._super(options);
    },

    onSceneLoaded: function () {
        this._super();
        var locationId = cleverapps.meta.selectedLocationId;
        UnitsLibrary.Switch(locationId);
        this.createMap(bundles["location_" + locationId].jsons.map_json.getJson());

        var orangery = Map2d.currentMap.orangery;
        if (orangery && !cleverapps.gameModes.noControls && !cleverapps.config.wysiwygMode) {
            var orangeryView = new OrangeryView(orangery);
            this.addChild(orangeryView);

            cleverapps.focusManager.registerControl(orangery.getControlName(), new HidingNode(orangeryView, cleverapps.UI.VERTICAL));
        }

        this.addPlayButton();

        this.runAction(new cc.RepeatForever(new cc.Sequence(
            new cc.DelayTime(5),
            new cc.PlaySound(bundles.main.urls.city_loop_effect),
            new cc.DelayTime(5)
        )));

        this.controller = new HomeController();
        if (cleverapps.meta.getMainObject().isCurrentLocation()) {
            this.addTasksIcon();
            cleverapps.focusManager.registerControl("finger_control", this.createListener(function (visible) {
                if (visible) {
                    this.createFinger();
                }
            }.bind(this)));

            cleverapps.meta.getMainObject().on("animateNewTask", this.animateNewTask.bind(this), this);
        }

        if (cleverapps.git) {
            cleverapps.git.changeFolders([GitManager.FOLDERS.UNIT_ANCHORS]);
        }
    },

    createMap: function (location) {
        this.map = new Map2d(location.map, {
            tiles: location.tiles,
            units: location.units,
            visibleBox: location.visibleBox,
            regions: location.regions,
            decorators: location.decorators,
            terrains: location.terrains,
            field: location.field,
            families: location.families
        });

        this.map.onUnitAvailableListener = function (unit) {
            cleverapps.unitsLibrary.openUnit(unit);
        };

        this.mapView = new Map2dView(this.map, this.mapPositionChanged.bind(this));
        this.addChild(this.mapView);
    },

    destructor: function () {
        this._super();
        this.controller && this.controller.destructor();
    },

    setupChildren: function () {
        if (!this.isAllLoaded) {
            return;
        }
        this._super();
        this.controller.onResize();
    },

    addPlayButton: function () {
        var playButton = this.playButton = new PlayButtonView({
            isSmall: true,
            hard: true
        });
        this.addChild(playButton);

        cleverapps.focusManager.registerControl("play_button", new HidingNode(playButton, cleverapps.UI.VERTICAL));
    },

    addTasksIcon: function () {
        var tasksIcon = new HomeTasksIcon(this);
        this.addChild(tasksIcon);

        this.tasksIcon = new HidingNode(tasksIcon, cleverapps.UI.VERTICAL);
        cleverapps.focusManager.registerControl("hometasks_icon", this.tasksIcon);

        cleverapps.aims.registerTarget("cookie", this.tasksIcon, {
            controls: ["hometasks_icon"]
        });
    },

    createFinger: function () {
        FingerView.hintClick(cleverapps.meta.getMainObject().getFurnitureToUpgrade() ? this.tasksIcon : this.playButton, {
            scale: cleverapps.styles.HomefixScene.finger.scale
        });
    },

    animateNewTask: function (furniture, f) {
        new NewTaskAnimation(furniture, this, f);
    },

    playIntroActions: function (f) {
        if (this.controller) {
            this.controller.showUp(f);
        } else {
            f();
        }
    },

    mapPositionChanged: function () {
        if (!this.mapView) {
            return;
        }

        var styles = this.getBackgroundStyles();

        if (!styles.parallax || !this.background) {
            return;
        }

        var scale = this.mapView.zoom - this.mapView.zoomHandler.minZoom;
        var safePadding = cleverapps.resolution.getSafePadding();
        var normalizedScale = Math.max(1.1 * this.height / this.background.height, 1.25 + HomefixScene.PARALLAX_FACTOR * scale) + 0.001 * (safePadding.top + safePadding.bottom);
        this.background.setScale(normalizedScale);

        var scroll = this.mapView.getCurrentScroll();
        var normalizedScroll = {
            x: 2 * (scroll.x - 0.5),
            y: 2 * (scroll.y - 0.5)
        };

        var x = this.width / 2 + HomefixScene.PARALLAX_FACTOR * this.width * normalizedScroll.x * (1 + scale);
        var y = this.height / 2 + HomefixScene.PARALLAX_FACTOR * this.height * normalizedScroll.y * (1 + scale);

        if (styles.parallax.hideHorizon) {
            x = this.width * (0.5 + 0.1 * normalizedScroll.x);
            y = this.height * (1.2 + 0.9 * normalizedScroll.y);
        }

        this.background.setPositionRound(x - safePadding.left, y - safePadding.bottom);
    },

    getBackgroundStyles: function () {
        return {
            movableAnimation: bundles.map_background.jsons.map_background_json,
            parallax: {
                hideHorizon: true
            }
        };
    },

    getPreferredBundles: function () {
        return ["main"];
    },

    cachedBundles: function () {
        return ["home_task_window", "make_choice_window"];
    },

    listBundles: function () {
        var result = this._super();

        result.push("location_" + cleverapps.meta.selectedLocationId);
        result.push(Map2d.getTilesTexture(cleverapps.meta.selectedLocationId));
        result.push(Map2d.getUnitsTexture(cleverapps.meta.selectedLocationId));

        return result;
    }
});

HomefixScene.PARALLAX_FACTOR = 1;

cleverapps.styles.HomefixScene = {
    mergeBonus: {
        x: 0,
        y: 30
    },

    finger: {
        scale: 0.7
    }
};
