/**
 * Created by evgeny on 11.06.2024
 */

FogsConfig.initialize = function () {
    FogsConfig.main = {
        fog0: {
            id: 0,
            headless: true
        },
        fog1: {
            id: 1,
            headless: true,
            parentFogId: "fog0",
            conditions: {
                family: "chloepetit"
            }
        },
        fog2: {
            id: 2,
            parentFogId: "fog1",
            headless: true
        },
        fog3: {
            id: 3,
            parentFogId: "fog2",
            headless: true
        },
        fog4: {
            id: 4,
            parentFogId: "fog3",
            headless: true
        },
        fog5: {
            id: 5,
            parentFogId: "fog4",
            headless: true
        }
    };

    FogsConfig.afterInitialize();
};

FogsConfig.initialize();
